import ApiService from '@/common/api.service'
import AlertService from '@/common/alert.service'

/* eslint-disable */
const getDefaultState = () => {
  return {
    regionDataVersion: null,

    regionGrups: [],
    regions: [],
    
    pcaRegionGrups: [],
    pcaRegions: [],
    
    ancestryGeoJson: [],
    modernPopGeoJson: null,
    modernPopGeoJsonResult: null,

    ancientPeriods: [],
    selectedAncientPeriod: {id: 0, number: 0, name: ''},
  }
}

const state = getDefaultState()

const getters = {
  getRegionGrups(state) {
    return state.regionGrups;
  },
  getRegions(state) {
    return state.regions;
  },

  
  getPcaRegionGrups(state) {
    return state.pcaRegionGrups;
  },
  getPcaRegions(state) {
    return state.pcaRegions;
  },

  getAncestryGeoJson(state) {
    return state.ancestryGeoJson;
  },
  getModernPopGeoJson(state) {
    return state.modernPopGeoJson;
  },
  getModernPopGeoJsonResult(state) {
    return state.modernPopGeoJsonResult;
  },

  getAncientPeriods(state) {
    return state.ancientPeriods;
  },
}

const mutations = {
  // order result
  setRegionGrups(state, results) {
    state.regionGrups = results.filter(x => x.presetRegionGrupType === 1);
    state.pcaRegionGrups = results.filter(x => x.presetRegionGrupType === 2);
  },
  setRegions(state, results) {
    state.regions = results.filter(x => x.presetRegionType === 1);
    state.pcaRegions = results.filter(x => x.presetRegionType === 2);
  },

  setAncestryGeoJson(state, ancestryGeoJson) {
    state.ancestryGeoJson = ancestryGeoJson;
  },

  setMdernPopGeoJson(state, modernPopGeoJson) {
    state.modernPopGeoJson = modernPopGeoJson;
  },
  setMdernPopGeoJsonResult(state, modernPopGeoJsonResult) {
    state.modernPopGeoJsonResult = modernPopGeoJsonResult;
  },

  // Ancient Periods
  setAncientPeriods(state, data) {
    state.ancientPeriods = data.result;
    const currentPeriods = data.result.filter(x => x.dataVersion === data.dataVersion);
    if (currentPeriods.length > 0) {
      state.selectedAncientPeriod = currentPeriods[0];
    }
  },
  setSelectedPeriod(state, selectedPeriod) {
    state.selectedAncientPeriod = selectedPeriod;
  },
  setDefaultPeriod(state, dataVersion) {
    const currentPeriods = state.ancientPeriods.filter(x => x.dataVersion === dataVersion);
    if (currentPeriods.length > 0) {
      state.selectedAncientPeriod = currentPeriods[0];
    }
  },
}

const actions = {
  fetchRegions(context, queryParams) {
    return ApiService.get('PresetRegion', queryParams, this)
      .then(response => {
        if (response.statusCode === 200) {
          context.commit('setRegions', response.result)
        } else {
          AlertService.error(this._vm, response.message)
        }
      })
  },
  fetchRegionGrups(context, queryParams) {
    return ApiService.get('PresetRegion/grups', queryParams, this)
      .then(response => {
        if (response.statusCode === 200) {
          context.commit('setRegionGrups', response.result)
        } else {
          AlertService.error(this._vm, response.message)
        }
      })
  },

  // Map Data
  fetchMapSourceDatas(context, queryParams) {
    return new Promise((resolve, reject) => {
      queryParams.dataVersion = context.rootGetters['orderResult/getDataVersion']

      ApiService.post('PresetMapData/sourcedata', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
  },

  fetchMapSourceModern(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.get('PresetMapData/moderngeojson', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
  },
  
  fetchMapDetailDatas(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.getSilent(`PresetMapData/${queryParams.id}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
  },

  // Farmer And HGs Data
  fetchFarmerAndHGsDatas(context, queryParams) {
    return new Promise((resolve, reject) => {
      queryParams.dataVersion = context.rootGetters['orderResult/getDataVersion']

      ApiService.post('PresetFarmerAndHGs/sourcedata', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
  },
  fetchFarmerAndHGsDetailData(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.get(`PresetFarmerAndHGs/${queryParams.sourceCode}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
  },

  // Ancient
  fetchAncientProxImageDatas(context, queryParams) {
    return new Promise((resolve, reject) => {
    queryParams.dataVersion = context.rootGetters['orderResult/getDataVersion']
    
      ApiService.post('ancientproxdata/sourcedata', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
  },

  fetchAncientProxDetailDatas(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.getSilent(`ancientproxdata/${queryParams.id}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
  },

  // Ancient Periods
  fetchAncientPeriods(context, queryParams) {
    return ApiService.get('PresetAncientPeriod', queryParams, this)
      .then(response => {
        if (response.statusCode === 200) {
          context.commit('setAncientPeriods', { result: response.result, dataVersion: context.rootGetters['orderResult/getDataVersion']})
        } else {
          AlertService.error(this._vm, response.message)
        }
      });
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
