import ApiService from '@/common/api.service'
import { PRODUCT_CODES, G25_CHECKOUT_STEPS } from '@/common/config';
import { getField, updateField } from 'vuex-map-fields';

/* eslint-disable */
let kitObj = { 
  id: 0, 
  kitName: '', 
  rawFileKey: null,
  rawFileName: '',
  rawFileFormat: '', 
  targetCoordinate: '', 
  presetEthnicityId: null,
  
  selectedEthnicityName: 'Select Ethnicity',
  isEmptyPresetEthnicityId: false,
  isLoading: false,
  emptyKitName: false,
  emptyRawFileKey: null,
  emptyTargetCoordinate: false,
  isShowDropdown: false,
};

const persistedState = JSON.parse(localStorage.getItem('deepACheckout'));

const getDefaultState = () => {
  return {
    selectedProductCode: PRODUCT_CODES.UPLOAD_G25_RAW_DATA,
    selectedStepCode: G25_CHECKOUT_STEPS.SELECT_SERVICE,

    productData: null,
    userData: {
      userEmail: null,
      firstName: null,
      lastName: null,
      password: null,
      twoFactorAuthActive: true,
    },
    createOrderData: {
      subtotal: null,
      paymentGuid: null,
      product: null,
      productQuantity: 1,
      paymentType: 2,
      promotionDetailGuid: null,
      kitList: [Object.assign({}, kitObj)],

      isFinishedPayment: false,
      isConfirmPrivacyPalicy: false,
    },
  }
}

const state = persistedState ? persistedState : getDefaultState()

const getters = {
  getCreateOrderData(state) {
    return state.createOrderData;
  },
  getCheckoutQuery(state) {
    const product = state.productData.product;
    const orderData = state.createOrderData;
    
    const queryParams = {
      _subtotal: orderData.subtotal.toString().replace(',', '.'),
      _discountSubtotal: orderData.discountSubtotal != null ? orderData.discountSubtotal.toString().replace(',', '.') : null,
    
      productId: product.id,
      paymentGuid: orderData.paymentGuid,
      promotionDetailGuid: orderData.promotionDetailGuid,
      kitList: orderData.kitList,
    };
    return queryParams;
  },
  getOrderSubTotal(state) {
    return state.createOrderData.discountSubtotal === null ? state.createOrderData.subtotal : state.createOrderData.discountSubtotal;
  },
  getProductData(state) {
    return state.productData;
  },

  // User
  getUserDataField(state) {
    return getField(state.userData);
  },
  getUserData(state) {
    return state.userData;
  },
}

const mutations = {
  setProductData(state, productData) {
    state.productData = productData;
  },
  setCreateOrderData(state, { key, value }) {
    state.createOrderData[key] = value;
  },

  setOrderKitsData(state, { index, key, value }) {
    state.createOrderData.kitList[index][key] = value;
  },

  setKitFileData(state, { index, rawFileKey, rawFileName, rawFileFormat }) {
    state.createOrderData.kitList[index].rawFileKey = rawFileKey;
    state.createOrderData.kitList[index].rawFileName = rawFileName;
    state.createOrderData.kitList[index].rawFileFormat = rawFileFormat;
    state.createOrderData.kitList[index].emptyRawFileKey = false;
  },
  removeKitFileData(state, { index }) {
    state.createOrderData.kitList[index].rawFileKey = null;
    state.createOrderData.kitList[index].rawFileName = '';
    state.createOrderData.kitList[index].rawFileFormat = '';
    state.createOrderData.kitList[index].emptyRawFileKey = true;
  },

  changeKitNumber(state, count) {
    /* eslint-disable */
    state.createOrderData.productQuantity = count;
    const fileLength = state.createOrderData.kitList.length;
    if(count > fileLength) {
      for (let index = fileLength; index < count; index++) {
        const newFile = Object.assign({}, kitObj);
        newFile.id = index;
        state.createOrderData.kitList.push(newFile)
      }
    }

    if(count < fileLength) {
      for (let index = 0; index < fileLength - count; index++) {
        state.createOrderData.kitList.pop();
      }
    }
  },

  resetOrderState(state) {
    kitObj = { 
      id: 0, 
      kitName: '', 
      rawFileKey: null,
      rawFileName: '',
      rawFileFormat: '', 
      targetCoordinate: '', 
      presetEthnicityId: null,
      
      selectedEthnicityName: 'Select Ethnicity',
      isEmptyPresetEthnicityId: false,
      isLoading: false,
      emptyKitName: false,
      emptyRawFileKey: null,
      emptyTargetCoordinate: false,
      isShowDropdown: false,
    };
    Object.assign(state, getDefaultState())
  },

  closeDropdowns(state) {
    state.createOrderData.kitList.forEach(element => {
      element.isShowDropdown = false;
    });
  },

  // User
  updateUserDataField(state, field) {
    updateField(state.userData, field);
  },
}

const actions = {
  saveCheckout(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('Checkout/g25', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
  },

  createPaymentIntent(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.postSilent('payment/create/intent', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
  },

  applyPromotionCode(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.postSilent('Promotion/apply/code', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
